import { Link } from "react-router-dom";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from '../../../Assets/logo12.jpeg'
import "./footer.css";

export default function footer() {
    return (
        <div id="footer">
            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="yo col-lg-3 col-md-6">
                            <div className="footer-info">
                              
                                <img className="logoo"  src={''}/>
                               
                               
                                <p>
                                8/3, Singapura main road, MS Palya post Circle, above Axis Bank ATM, Vidyaranyapura, Bengaluru, Karnataka 560097
                                    <br />
                                 
                                    <strong>
                                        Telefono:
                                    </strong>
                                    +91 961 111 4379
                                    <br />
                                    
                                    <strong>
                                        Email:
                                    </strong>
                                    info@kbnprints.in
                                    <br />
                                </p>
                                <div className="social-links mt-3">
                                    <a href="/#" className="twitter"><i className="bi bi-facebook" /></a>
                                    <a href="/#" className="facebook"><i className="bi bi-twitter" /></a>
                                    <a href="/#" className="instagram"><i className="bi bi-instagram" /></a>
                                   
                                </div>
                            </div>
                        </div >
                      
                        <div className="col-lg-4 col-md-6 footer-links ">
                        
                            <ul>
                                <li>
                                    <i className="bx bx-chevron-right"></i>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <i className="bx bx-chevron-right"></i>
                                    <Link to="/nosotros">Menu</Link>
                                </li>
                                <li>
                                    <i className="bx bx-chevron-right"></i>
                                    <Link to="/menu">Gallery</Link>
                                </li>
                                
                                <li><i className="bx bx-chevron-right"></i>
                                    <Link to="/contacto">Contact Us</Link>
                                </li>
                            </ul>
                        </div>

                        

                        <div className="col-lg-5 col-md-6 footer-newsletter">
                        
                      <iframe className="f-map" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=KBN%20Prints%20,8/3,%20Singapura%20main%20road,%20MS%20Palya%20post%20Circle,%20above%20Axis%20Bank%20ATM,%20Vidyaranyapura,%20Bengaluru,%20Karnataka%20560097+(kbn%20prints)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                      


                     



                        </div>
                    </div >
                </div >
            </div >

        </div>

    );
}
