import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";

import './Point.css';
import '../../page/App'

import img4 from '../../../Assets/xerox1.jpeg';

function acercadeC() {
    return (
        <>
            <section id="about" className="about">
                <div className="container" >

                    <div className="row">
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="about-img">
                                <img style={{borderRadius:'20px'}} src={img4} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                            <h3 style={{ textAlign: 'left' }}>About Us</h3>
                            <br/>
                            
                            <p className="fst-italic" style={{ textAlign: 'left' }}>
                            KBN is a retail chain that offers print and document services all under one roof. We aim to make printing a simple and hassle-free process for both business customers and individuals. Together we can work with you to create anything from business cards, letterheads, marketing brochures or exhibition banners. We even have a library of templates for you to choose from to make invitations cards, create personalized gifts and process photo prints at cost-effective prices.
                            </p>
                            <div className="points">
                            <ul>
                                <li style={{ textAlign: 'left'}}><i className="bi bi-check-circle-fill" style={{ fontSize: "1.5em", color: '#cda45e'}}></i> Educational banner printing</li>
                                <li style={{ textAlign: 'left'}}><i className="bi bi-check-circle-fill" style={{ fontSize: "1.5em", color: '#cda45e'}}></i>  Industrial  banner printing </li>
                                <li style={{ textAlign: 'left'}}><i className="bi bi-check-circle-fill" style={{ fontSize: "1.5em", color: '#cda45e'}}></i>  Shops banner printing printing</li>
                               
                            </ul>
                            
                           
                            </div>
                            
                            <a href="/Pages">
                            <button style={{backgroundColor:'white',color:'black'}} >Read More</button>
                            </a>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default acercadeC;
