import Carousel from 'react-bootstrap/Carousel'
import './File.css';

function eventosC() {
    return (
        <>
            <section id="events" className="events">
                <div className=" container ">
                    <div className=" section-title ">
                        <div className=" titulo-seccion ">
                            <h2>EVENTS</h2>
                        </div>

                    </div>
                </div>
                <Carousel>

                    <Carousel.Item>
                        <div className="pad row slider slider-eventos">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <img className="img-fluid mb-3 mb-lg-0 imgss" src="https://stevenedev.github.io/Cafe-Bar/img/event-birthday.jpg" alt="" /></div>
                                <div className="col-md-6">
                                    <div className="text-lg-left">
                                        <h4>Come & Join Us</h4>
                                        <p className="mb-0">Get ready for an electrifying experience at B1 Pub! Join us for pulsating live music, themed parties, and exclusive events. Our dynamic calendar ensures there's something for everyone, from trivia nights to special celebrations. Elevate your nights and make memories at B1 Pub – where every event is a reason to celebrate!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="pad row slider slider-eventos">
                            <div className="row align-items-center">
                                <div className="col-md-6">
                                    <img className="img-fluid mb-3 mb-lg-0 imgss" src="https://stevenedev.github.io/Cafe-Bar/img/event-private.jpg" alt="" /></div>
                                <div className="col-md-6">
                                    <div >
                                        
                                        <p className="mb-0">B1 Pub beckons with a tapestry of events! Immerse yourself in live music vibes, explore themed nights, and discover exclusive experiences that define us. From soulful acoustic sessions to vibrant dance parties, each night is a unique celebration. Join us for curated cocktail evenings that elevate your spirits. B1 Pub is where every moment becomes a memorable event!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    


                </Carousel>
            </section>
        </>
    );
}

export default eventosC;