import React from 'react';
import img6 from '../../../Assets/flexbanners.jpeg';
import './menu.css';
import  img7 from '../../../Assets/brouchers.jpg';

import  img8 from '../../../Assets/Final biryani flyer jpg.jpg';
import  img9 from '../../../Assets/f1.jpeg';

import  img10 from '../../../Assets/f2.jpeg';
import  img11 from '../../../Assets/f3.jpeg';

import  img12 from '../../../Assets/f4.jpeg';
import  img13 from '../../../Assets/f5.jpeg';

import  img14 from '../../../Assets/f6.jpeg';

const menucontainer = () => {
  return (
    <>

<br/>
<br/>
<br/>


<div className="container">
          <div className="section-title">
            <h1 style={{textAlign:'center'}}>Our Services</h1>
         
          </div>
        </div>

    <div className='menu-all'>
        <div className=' menu-1'>
           <div className='menu-img1'>
            <img src={img6}/>
           </div>
           <div className='menu-des1'>
            <h3>Flex Banners</h3>
            <p>
            KBNPRINTS ,the leading company in flyer printing in India, brings you the best event flyer printing services. Promote a new venture or an important occasion with attractive, colorful, and eye-catching flyers. Choose from single-sided or double-sided flyers, and optimize the placement of your information and graphics. KBNPRINTS high-quality promotional flyer printing services ensure that the vibrant consistency of the flyers is maintained throughout the print run.
            </p>
           </div>
        </div>
       <br/>

       <div className=' menu-1'>
           
           <div className='menu-des1'>
            <h3>Brochures</h3>
            <p>
            Market your business and products with our professional online brochure printing services. Brochures are one of the most effective and versatile tools of marketing. Use our tri-fold brochures or bi-fold brochures to put your best foot forward. Make an eye-catching brochure that grabs the attention of the readers in the first instance and serves your marketing goals. Choose from unlimited colors that complement your brand and highlight the achievements of your business. Educate your potential customers and create awareness about your brand with neatly designed brochures. Make sure adequate information is conveyed through text and visuals that will help the potential customers in making an informed decision.
            </p>
           </div>
           <div className='menu-img1'>
            <img src={img7}/>
           </div>
        </div>

        <br/>

        <div className=' menu-1'>
           <div className='menu-img1'>
            <img src={img8}/>
           </div>
           <div className='menu-des1'>
            <h3>Vinyl Printing</h3>
            <p>
            Vinyl pricing is a nuanced realm, influenced by various factors that collectors and enthusiasts must navigate. One pivotal element shaping prices is the record's rarity, with scarce finds often commanding higher sums. Additionally, the condition of the vinyl, graded from mint to poor, significantly impacts its market value. Beyond rarity and condition, factors like artist popularity and historical significance play pivotal roles in determining pricing. 
            </p>
           </div>
        </div>
        
        <br/>

        <div className=' menu-1'>
           
           <div className='menu-des1'>
            <br/>
            <h3>Flyers, Leaflets & Pamphlets Printing</h3>
            <p>
            Advertise and market your services in small or Mass distribution like in Events! If you’re trying to introduce your business to people on the street; flyers, pamphlets or leaflets help you get noticed – and stay top-of-mind.Elevate your marketing strategies with eye-catching flyers designed to capture attention and convey your message succinctly. Our leaflets offer a versatile platform to showcase detailed information, ideal for educational materials or product showcases. 
            </p>
           </div>
           <div className='menu-img1'>
            <img src={img9}/>
           </div>
        </div>

        <br/>
        <div className=' menu-1'>
        <div className='menu-img1'>
            <img src={img10}/>
           </div>
           <div className='menu-des1'>
            <br/>
            <h3>Graphic Design Services</h3>
            <p>
            With millions of users constantly online, digital marketing is the way to go for businesses! Create stunning marketing visuals for your brand to gain a dominating presence in the digital era.Our team of experienced designers specializes in creating stunning graphics that bring your ideas to life and elevate your brand image. Whether you need eye-catching logos, engaging social media graphics, or professional business cards, we've got you covered.
            </p>
           </div>
           
        </div>
     
     <br/>

        <div className=' menu-1'>
           
           <div className='menu-des1'>
           <br/>
            <h3>Billbooks & Letterheads Printing</h3>
            <p>
            Branding is one of the most important aspect to any company and having custom printed letterheads and bill book is the best way to do it. Choose from a variety of themes available with us. Transform your business communications with our Billbooks & Letterheads Printing services. Enhance professionalism and brand consistency with custom-designed billbooks and letterheads that reflect your company's identity and values. 
            </p>
           </div>
           <div className='menu-img1'>
            <img src={img11}/>
           </div>
        </div>


        <br/>

        <div className=' menu-1'>
           <div className='menu-img1'>
            <img src={img12}/>
           </div>
           <div className='menu-des1'>
            <br/>
            <h3>Invitation Cards & Greeting Cards</h3>
            <p>
            Want to make appealing Invitations to your guest or Loved ones? Make them feel that they wouldn't want to miss your event!.

Elevate your celebrations and heartfelt messages with our Invitation Cards & Greeting Cards Printing services. Whether you're planning a special event or sending warm wishes, our custom-designed cards add a personal touch that makes every moment memorable. 
            </p>
           </div>
        </div>


        <br/>

        <div className=' menu-1'>
           
           <div className='menu-des1'>
            <br/>
            <h3>Professional Certificates</h3>
            <p>
            Personalized Certificates are perfect for acknowledging great performers. Choose from a range of Standard, Premium & Framed Certificates.Celebrate achievements and showcase expertise with our Professional Certificates Printing services. Whether you're recognizing employee accomplishments or awarding certifications, our custom-designed certificates add prestige and value to every achievement. 
            </p>
           </div>
           <div className='menu-img1'>
            <img src={img13}/>
           </div>
        </div>


        <br/>


        <div className=' menu-1'>
        <div className='menu-img1'>
            <img src={img14}/>
           </div>
           <div className='menu-des1'>
            <br/>
            <h3>Custom Printed Color Booklets</h3>
            <p>
            Customized best booklet printing service online in India, Online booklet printing is made simple with the effective design interfaceWith our high-quality printing techniques and attention to detail, you can trust us to deliver color booklets that captivate readers and leave a lasting impression. Whether it's showcasing products, sharing insights, or promoting events, our printing services ensure that your booklets stand out and engage your target audience..</p>
           </div>
           

        </div>
        


        <br/>

        
    </div>
    </>
  )
}

export default menucontainer