import React from 'react';
import './Menu1.css';
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import img9 from '../../../Assets/f1.jpeg';
import img10 from '../../../Assets/f2.jpeg'
import img11 from '../../../Assets/f3.jpeg'

const Menu1 = () => {
  return (
    <>
    
    
    
        <div className='m-header'>
            <h1>Discover Our Services</h1>
            <br/>
            <div className='menuu-all'>
                <div className='menu-c'>
                    
                    <img className='imggg' src={img9}/>
                    <h5 className='h33'>Flyers, Leaflets & Pamphlets Printing</h5>
                    <p className='para12'>Advertise and market your services in small or Mass distribution like in Events! If you’re trying to introduce your business to people on the street; flyers, pamphlets or leaflets help you get noticed – and stay top-of-mind.</p>
                    
                   
                </div>

                <br/>
                
         
                <div className='menu-c'>
                <div>
                    <img className='imggg' src={img10}/>
                   
                    <h5 className='h33'>Graphic Design Services</h5>
                    <p className='para12'>With millions of users constantly online, digital marketing is the way to go for businesses! Create stunning marketing visuals for your brand to gain a dominating presence in the digital era.</p>
                </div>
                </div>
            
      <br/>

                <div className='menu-c'>
                <div>
                    <img className='imggg' src={img11}/>
                   
                    <h5 className='h33'>Billbooks & <br/>Letterheads Printing</h5>
                    <p className='para12'>Branding is one of the most important aspect to any company and having custom printed letterheads and bill book is the best way to do it. Choose from a variety of themes available with us.</p>
                </div>
               
                </div>
            </div>

       
        <br/>
        <br/>
   
        <Nav.Link as={Link} to="/Menu">
        <div className='b-1'>
                 <button>View All Services</button>
                 </div>
                 </Nav.Link>
    </div>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    </>
  )
}

export default Menu1