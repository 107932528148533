import React from 'react';
import './Gallery.css';
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import img1 from '../../../Assets/kbn1.jpeg';
import img2 from '../../../Assets/kbn12.jpeg';
import img3 from '../../../Assets/kbn13.jpeg';

import img5 from '../../../Assets/kbn14.jpeg';
import img6 from '../../../Assets/kbn16.jpeg';
import img7 from '../../../Assets/Final biryani flyer jpg.jpg';


const Gallery = () => {
  return (
    <>
   
    <div className='g-all'>
    
        <div className='g-header'>
            <h1>Gallery</h1>
        </div>

        <div className='g-parent'>
            
        <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img1} />
            </div>

            <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img2} />
            </div>

            <div className='g-photo'>
                <img style={{borderRadius:'30px'}} src={img3}/>
            </div>

           
            

           
        </div>


        <div className='g-parent'>
            
            <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img5} />
                </div>
    
                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img6} />
                </div>
    
                <div className='g-photo'>
                    <img style={{borderRadius:'30px'}} src={img7} />
                </div>

               
                
    
               
            </div>

<br/>
<Nav.Link as={Link} to="/Gallery1">
           <div className='g-button'>
            
            <button style={{borderRadius:'30px'}}>See More</button>
           
            
           </div> 
           </Nav.Link>
            


         
    </div>
    <br/>
    </>
  )
}

export default Gallery