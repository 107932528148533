import Carousel from 'react-bootstrap/Carousel';

import slider2 from '../../../Assets/s1212.jpeg';
import slider4 from '../../../Assets/s1214.jpeg'






export default function slider() {
  return (
    <>
            <Carousel>
        
        <Carousel.Item interval={500}>
          <br/>
          <br/>
          <br/>
          <img
            className="d-block w-100 "
            src={slider2}
            alt="Second slide"
          />
          <Carousel.Caption>
          </Carousel.Caption>
        </Carousel.Item>
       

        <Carousel.Item>
        <br/>
          <br/>
          <br/>
          <img
            className="d-block w-100 "
            src={slider4}
            alt="Third slide"
          />
          <Carousel.Caption>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
