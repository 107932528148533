import "./header.css";
import { Navbar, Nav } from "react-bootstrap";
import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";


import logo from '../../../Assets/logo12.jpeg'

export default function header() {
  return (
    <>
      <div className="navbar">
        <Navbar
          collapseOnSelect
          expand="lg"
          className="fixed-top d-flex align-items-center "
          variant="dark">

          <Navbar.Brand>
            <Link to="/"> 
                <img className='logoo' src={''} />
            </Link></Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav ">
            <Nav className="me-auto items" >


              <Nav.Link as={Link} to="/">
                <div className="item">
                  Home
                </div>
              </Nav.Link>
              
              <Nav.Link as={Link} to="/menu">
                <div className="item">
                  Our Services
                </div>
              </Nav.Link>
              <Nav.Link as={Link} to="/Gallery1">
                <div className="item">
                  Gallery
                </div>
              </Nav.Link>
              <Nav.Link as={Link} to="/Pages">
                <div className="item">
                  About Us
                </div>
              </Nav.Link>
              <Nav.Link as={Link} to="/contacto">
                <div className="item">
                  Contact
                </div>
              </Nav.Link>


                

             

             

            </Nav>

          </Navbar.Collapse>

        </Navbar>
      </div>
    </>
  );
}
