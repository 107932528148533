import "bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";

import './About.css';


import img4 from '../../../Assets/xerox1.jpeg';
import img5 from '../../../Assets/brouchers.jpg';

function Pages() {
    return (
        <>
        <br/>
        <br/>
        <br/>
              <div className="container">
          <div className="section-title">
            <h1 style={{textAlign:'center'}}>About Us</h1>
         
          </div>
        </div>

            <section id="about" className="about">
                <div className="container" >

                    <div className="row">
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="about-img">
                                <img src={img4} alt="" />
                            </div>
                            <br/>
                            <div className="about-img">
                                <img  className="imgg4" src={img5} alt="" />
                            </div>
                        </div>
                        
                        
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                            <h3 style={{ textAlign: 'left' }}>Our Story</h3>
                            
                
                            <p className="fst-italic" style={{ textAlign: 'left' }}>
                            It was born out of a vision to create a place run by happy people who delivered happy customers and catered to the printing needs of almost everyone. With some inspired teamwork, peppered liberally with start-up mistakes, KBN's initial crew slowly built a business that is known today for its steadfast service and colourfast printing.KBN makes printing easy!
                            </p>
                           
                            
                            <br/>

                       
                            <h3 style={{ textAlign: 'left' }}>Who Are We?</h3>
                            
                
                            <p className="fst-italic" style={{ textAlign: 'left' }}>
                            KBN is a retail chain that offers print and document services all under one roof. We aim to make printing a simple and hassle-free process for both business customers and individuals. Together we can work with you to create anything from business cards, letterheads, marketing brochures or exhibition banners. We even have a library of templates for you to choose from to make invitations cards, create personalized gifts and process photo prints at cost-effective prices.
                            <br/>
                            <br/>
                            
                            </p>
                           
                            <br/>

                            <h3 style={{ textAlign: 'left' }}>You can reach us through any of the following ways:</h3>
                            
                
                            <p className="fst-italic" style={{ textAlign: 'left' }}>
                            (1). At any KBN store located near you.<br/>
                            (2). You can place orders online at www.kbnprints.in<br/>
                            (3). Call us at +91 961 111 4379
                            <br/>
                            <br/>
                            What's even better is that we deliver the prints
                           
                            </p>
                           
                            
                            <br/>

                            <h3 style={{ textAlign: 'left' }}>We cant Wait To heat from you!</h3>
                            
                
                            <p className="fst-italic" style={{ textAlign: 'left' }}>
                            If you would like to know more about us, send us an email at info@kbnprints.in and we'll answer your queries. Remember, we go the extra mile to bring innovative ideas that make printing easy and fun. Try us and experience the difference!
                            </p>
                           
                            
                            <br/>
                            
                        </div>
                       
                    </div>

                </div>
            </section>
        </>
    );
}

export default Pages;
