import React from "react";
import { useEffect } from 'react';
import emailjs from "emailjs-com";
import Swal from "sweetalert2";


import "./contactenos.css";


export default function ContactoC() {
  
  useEffect(() => {
   
    window.scrollTo(0, 0);
  }, []);

  
   
      
      // Swal.fire({
      //   icon: "success",
      //   title: "Message Sent!",
      //   text: "Your message has been sent successfully.",
      // });
  
      
     

  

  return (
    <>
      <section id="contact" className="contact">
        <div className="container">
          <div className="section-title">
            <h1 style={{textAlign:'center'}}>Contact Us</h1>
         
          </div>
        </div>
      <br/>
      <br/>
      <br/>
        <div className="container">
          <div className="section-title">
            <h1 style={{textAlign:'center'}}>Send us a Message</h1>
         
          </div>
        </div>
        
        <div className="all12 col-lg-8 mt-5 mt-lg-0 con-all">
              <form  className="php-email-form"  onSubmit={''}>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      id="name"
                      placeholder="Full name"
                      required
                    
                    />
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="email"
                      placeholder="Mail Id"
                      required
                      
                    
                    />
                  </div>
                </div>

                

                <div className="form-group mt-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="8"
                    placeholder="Message"
                    required
                    
                  
                  ></textarea>
                </div>

               

                <br />

                <div className="my-3">
                  <div className="loading">Cargando</div>
                  <div className="error-message"></div>
                 
                </div>
                <div className="text-center">
                  <button type="submit">Send Your Message</button>
                </div>
              </form>
            </div>
            <br/>
            <br/>
            <br/>

        <div className="c-address">
          <div className="c1-address">
            
            <h3>Head Office:</h3>
           
            <br/>
            <div className="c11">
            <div className="info">
            <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p style={{color:'black'}}>8/3, Singapura main road, MS Palya post Circle, above Axis Bank ATM, Vidyaranyapura, Bengaluru, Karnataka 560097</p>
            </div>

            <div className="open-hours">
                  <i className="bi bi-clock"></i>
                  <h4>Timings:</h4>
                  <p style={{color:'black'}}>
                    Mon-Sun:
                    <br />
                    11:00 AM - 11:30 PM
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p style={{color:'black'}}>info@kbnprints.in</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Phone No:</h4>
                  <p style={{color:'black'}}>+91 961 111 4379</p>
                </div>

            </div>
            </div>
          </div>
          <br/>

         <div className="c-address">
          <div className="c1-address">
            <h3>Branch Office:</h3>
            <br/>
            <div className="c11">
            <div className="info">
            <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p style={{color:'black'}}>3GRM+PJC, Lakshmipura cross, Bengaluru, Karnataka 560097</p>
            </div>

            <div className="open-hours">
                  <i className="bi bi-clock"></i>
                  <h4>Timings:</h4>
                  <p style={{color:'black'}}>
                    Mon-Sun:
                    <br />
                    11:00 AM - 11:30 PM
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p style={{color:'black'}}>info@kbnprints.in</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Phone No:</h4>
                  <p style={{color:'black'}}> +91 961 111 4379</p>
                </div>

            </div>
            </div>
          </div>
          </div>

          <br/>

          <div className="c1-address">
            <h3>Branch Office:</h3>
            <br/>
            <div className="c11">
            <div className="info">
            <div className="address">
                  <i className="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p style={{color:'black'}}>3GRM+PJC, Lakshmipura cross, Bengaluru, Karnataka 560097</p>
            </div>

            <div className="open-hours">
                  <i className="bi bi-clock"></i>
                  <h4>Timings:</h4>
                  <p style={{color:'black'}}>
                    Mon-Sun:
                    <br />
                    11:00 AM - 11:30 PM
                  </p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p style={{color:'black'}}>info@kbnprints.in</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone"></i>
                  <h4>Phone No:</h4>
                  <p style={{color:'black'}}>+91 961 111 4379</p>
                </div>

            </div>
            </div>
          </div>
        </div>


        
  <br/>
  <br/>
  <br/>

        

        

        <div className="c-map" style={{marginLeft:'150px'}}>
        <iframe  style={{width:'40%',height:'360px',paddingRight:'20px'}} className="f-mapp" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=KBN%20Prints%20Attur%20Main%20Rd,%20above%20Medplus%20medical,%20Attur%20Layout,%20Yelahanka%20New%20Town,%20Bengaluru,%20Karnataka%20560064+(kbn%20prints)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>

        <iframe  style={{width:'40%',height:'360px'}} className="f-mapp" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=KBN%20Prints%20,lakshmipura%20Cross,%20Bengaluru,%20Karnataka%20560097+(kbn%20prints)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>


      </section>
    </>
  );
}







